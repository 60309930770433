import React, {useEffect, useState, useCallback}  from 'react';
import { useSelector }                            from 'react-redux';

import api                                        from "../Utils/Axios";
import { BACKEND_URL }                            from '../Utils/constant';
import { REFRESH_FLEET_DATA_EVERY_X }             from '../Utils/constant';
import vehicleImage                               from './car_icon.png';

const image = new Image();
image.src = vehicleImage;

export default function FleetsContainer(props) {
    const {map} = props;
    const [fleetData, setFleetData] = useState(() => {
      return JSON.parse(sessionStorage.getItem('fleetData')) || [];
    });
    const fleetDisplay = useSelector((state) => state.fleetDisplay);

    const getData = useCallback(
      async() => {
        try {
          let res = await api.get(BACKEND_URL+'/fleetdata',{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          if(res.data){
            sessionStorage.setItem('fleetData', JSON.stringify(res.data.data));
            setFleetData(Array.isArray(res.data.data) ? res.data.data : []);
          }else{
            console.log("no data");
          }
        }
        catch(error){
          console.log(error);
        }
      }
    );

    function reorderLayers(){
      const layers = map.current.getStyle().layers;
      if(map.current?.isStyleLoaded()){
        for(const fleet of fleetData){
          if(map.current.getLayer('fleet_' + fleet.senderId.toString())){
            for(const layer of layers){
              if(layer.id.startsWith('tile_')){
                 map.current.moveLayer('fleet_' + fleet.senderId.toString(), layer.id);
              }
            }
          }
        }
      }
    }

    useEffect(() => {
      getData();
    }, []);

    useEffect(() => {
      if(fleetDisplay){
        const timer = setInterval(() => {
          getData();
          reorderLayers();
        }, REFRESH_FLEET_DATA_EVERY_X * 1000);
        return () => clearInterval(timer);
      }
    }, [fleetData, fleetDisplay]);

    useEffect(() => {
      map.current?.on('load', () => {
        // Check if the fleet image is already added to prevent duplicate addition
        if (!map.current.hasImage('fleet')) {
          try {
            map.current.addImage('fleet', image);
          } catch (error) {
            console.log(error);
          }
        }

        if (fleetDisplay) {
          setTimeout(() => {
            if (Array.isArray(fleetData)) {
              for (const element of fleetData) {
                const sourceId = 'fleet_' + element.senderId.toString();
                const layerId = 'fleet_' + element.senderId.toString();

                // Check if the source already exists before adding
                if (!map.current.getSource(sourceId)) {
                  map.current.addSource(sourceId, {
                    type: 'geojson',
                    data: {
                      type: 'FeatureCollection',
                      features: [
                        {
                          type: 'Feature',
                          geometry: {
                            type: 'Point',
                            coordinates: [element.longitude, element.latitude]
                          },
                          properties: {
                            title: element.vehicleModel.toString(),
                            icon: 'fleet'
                          }
                        }
                      ]
                    }
                  });
                }

                // Check if the layer already exists before adding
                if (!map.current.getLayer(layerId)) {
                  map.current.addLayer({
                    id: layerId,
                    type: 'symbol',
                    source: sourceId,
                    layout: {
                      'icon-image': ['get', 'icon'],
                      'icon-size': 0.1,
                      'text-field': ['get', 'title'],
                      'text-font': ['Montserrat'],
                      'text-size': 13,
                      'text-offset': [0, 2],
                      'text-anchor': 'top',
                      'icon-allow-overlap': true,
                      'text-allow-overlap': true,
                      'icon-rotate': parseInt(element.heading)
                    },
                    paint: {
                      'text-color': '#000000',
                      'icon-color': '#A7ED4C',
                      'icon-halo-color': '#000',
                      'icon-halo-width': 2
                    }
                  });
                }
              }
            }
          }, 1000);
        }
        map.current.triggerRepaint();
      });
    }, [fleetData, fleetDisplay]);

    useEffect(() => {
      map.current?.triggerRepaint();
      if(map.current?.isStyleLoaded()){
        if(fleetDisplay){
          if(fleetData.length > 0){
            for(const element of fleetData){
              const sourceId = 'fleet_' + element.senderId;
              const layerId = 'fleet_' + element.senderId;

              // Remove the existing source and layer if they exist before adding a new one
              if(map.current.getLayer(layerId)){
                map.current.removeLayer(layerId);
              }
              if(map.current.getSource(sourceId)){
                map.current.removeSource(sourceId);
              }

              // Add new source and layer
              map.current.addSource(sourceId, {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: [
                    {
                      type: 'Feature',
                      geometry: {
                        type: 'Point',
                        coordinates: [element.longitude, element.latitude]
                      },
                      properties: {
                        title: element.vehicleModel.toString(),
                        icon: 'fleet'
                      }
                    }
                  ]
                }
              });

              map.current.addLayer({
                id: layerId,
                type: 'symbol',
                source: sourceId,
                layout: {
                  'icon-image': ['get', 'icon'],
                  'icon-size': 0.1,
                  'text-field': ['get', 'title'],
                  'text-font': ['Montserrat'],
                  'text-size': 13,
                  'text-offset': [0, 2],
                  'text-anchor': 'top',
                  'icon-allow-overlap': true,
                  'text-allow-overlap': true,
                  'icon-rotate': parseInt(element.heading)
                },
                paint: {
                  'text-color': '#000000',
                  'icon-color': '#A7ED4C',
                  'icon-halo-color': '#000',
                  'icon-halo-width': 2
                }
              });
            }
          } else {
            // Remove any existing fleet layers if no fleet data
            const layers = map.current.getStyle().layers;
            if(layers){
              for(const layer of layers){
                if(layer.id.startsWith('fleet_')) map.current.removeLayer(layer.id);
              }
            }
          }
        } else {
          // Clear fleet layers when fleetDisplay is false
          if(Array.isArray(fleetData)){
            for(const element of fleetData){
              if(map.current.getLayer('fleet_'+element.senderId)){
                map.current.removeLayer('fleet_'+element.senderId);
              }
              if(map.current.getSource('fleet_'+element.senderId)){
                map.current.removeSource('fleet_'+element.senderId);
              }
            }
          }
        }
        map.current.triggerRepaint();
      }
    }, [fleetData, fleetDisplay]);

    return(
        <div>Fleets...</div>
    );
}
