function getColor(value, maxValue, minValue) {
    let h = 0.0, s = 0.0, v = 0.0;
    if(maxValue === minValue)   h = 0.33;
    else                        h = 0.33 * ((value - minValue) / (maxValue -  minValue));
    s = 1.0;
    v = 1.0;
    h = h * 360;

    let c = s * v;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = v - c;
    
    let rPrime, gPrime, bPrime;
    
    if(h >= 0 && h < 60){
        rPrime = c;
        gPrime = x;
        bPrime = 0;
    }else if(60 <= h && h < 120) {
        rPrime = x;
        gPrime = c;
        bPrime = 0;
    }else if(120 <= h && h < 180) {
        rPrime = 0;
        gPrime = c;
        bPrime = x;
    }else if(180 <= h && h < 240) {
        rPrime = 0;
        gPrime = x;
        bPrime = c;
    }else if(240 <= h && h < 300) {
        rPrime = x;
        gPrime = 0;
        bPrime = c;
    }else if(300 <= h && h < 360) {
        rPrime = c;
        gPrime = 0;
        bPrime = x;
    }else{
        // This should not happen, but just in case
        rPrime = 0;
        gPrime = 0;
        bPrime = 0;
    }

    let r = Math.round((rPrime + m) * 255);
    let g = Math.round((gPrime + m) * 255);
    let b = Math.round((bPrime + m) * 255);

    const hexR = (Math.round(r).toString(16).length === 2)? Math.round(r).toString(16) : "0" + Math.round(r).toString(16);
    const hexG = (Math.round(g).toString(16).length === 2)? Math.round(g).toString(16) : "0" + Math.round(g).toString(16);
    const hexB = (Math.round(b).toString(16).length === 2)? Math.round(b).toString(16) : "0" + Math.round(b).toString(16);

    return [hexR, hexG, hexB];
}

function getTileColor(choice, version, size, date, maxVersion, maxSize, maxDate, minDate) {
    switch(choice){
        case 0: return "#808080"; // GREY
        case 1: return colorsByVersion(version, maxVersion);            // For coloring by version
        case 2: return colorsBySize(size, maxSize);                     // For coloring by size
        case 3: return colorsByDate(version, date, maxDate, minDate);   // For coloring by date
        default: return "#808080";
    }
}

function colorsBySize(value, max) {
    if(value === 0) return "#3b4544"; // BLACK
    const [hexR, hexG, hexB] = getColor(max - value, max, 0);
    return "#" + hexR + hexG + hexB;
}

function colorsByVersion(value, max) {
    if(value === 0) return "#3b4544"; // BLACK
    const [hexR, hexG, hexB] = getColor(value, max, 1);
    return "#" + hexR + hexG + hexB;
}

function colorsByDate(version, date, maxDate, minDate) {
    if(version === 0  || version === 1) return "#3b4544"; // BLACK
    const [hexR, hexG, hexB] = getColor(date, maxDate, minDate);
    return "#" + hexR + hexG + hexB;
}

module.exports = {
    getTileColor 
};
