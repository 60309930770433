import React, {useEffect, useState, useCallback, useReducer}  from 'react';
import { ToastContainer }                                     from "react-toastify";

import { getTileColor }                                       from '../Utils/TileColors';
import api                                                    from "../Utils/Axios";
import TileOverlay                                            from './TileOverlay';
import { REFRESH_DATA_EVERY_X }                               from '../Utils/constant';
import { BACKEND_URL }                                        from '../Utils/constant';

export default function PointCloudLayer(props) {
  const {map, layer, coloring} = props;
  const [maxVersion, setMaxVersion] = useState(() => {
    return parseInt(sessionStorage.getItem('maxVersionLayer'+layer));
  });
  const [maxSize, setMaxSize] = useState(() => {
    return parseInt(sessionStorage.getItem('maxSizeLayer'+layer));
  });
  const [maxDate, setMaxDate] = useState(() => {
    return parseInt(sessionStorage.getItem('maxDateLayer'+layer));
  });
  const [minDate, setMinDate] = useState(() => {
    return parseInt(sessionStorage.getItem('minDateLayer'+layer));
  });
  const [pointCloudData, setPointCloudData] = useState(() => {
    return JSON.parse(sessionStorage.getItem('layer'+layer)) || [];
  });
  const [spanValue, setSpanValue] = useState(() => {
    let rtd = localStorage.getItem('realTimeDisplay');
    if(rtd === "true")  return "check_circle";
    else                return "check_circle_outline";
  });
  const [counter, setCounter] = useState(false);
  const [displayTileOverlay, setDisplayTileOverlay] = useState(false);
  const initialTileMetadata = {tileId: 0, tileVersion:0, tileSize: 1, tileDate: 0, tileLevel: 18, tileExtension: "", tileFilePath: ""};
  const [tileMetadata, dispatch] = useReducer((state, action) => { return action}, initialTileMetadata);
  const [refreshTitle, setRefreshTtitle] = useState(() => {
    let rtd = localStorage.getItem('realTimeDisplay');
    if(rtd === "true") return "Disable real time display";
    else    return "Enable real time display";
  });
  const [realTimeDisplay, setRealTimeDisplay] = useState(() => {
    return localStorage.getItem('realTimeDisplay') === "true";
  });
  const [RefreshingMessage, setRefreshingMessage] = useState("");

  const handleClick = useCallback(() => {
    for(const elem of pointCloudData){
      if(map.current.getPaintProperty('tile_'+elem.id+'_border', 'line-color') === "#0075a9"){
        map.current.setPaintProperty('tile_'+elem.id+'_border', 'line-color', map.current.getPaintProperty('tile_'+elem.id, 'fill-color'));
        map.current.setPaintProperty('tile_'+elem.id+'_border', 'line-width', 1.5);
      }
    }
    setDisplayTileOverlay(false);
  }, [pointCloudData]);

  function refreshData(e){
    e.preventDefault();
    if(spanValue === "check_circle"){
      setSpanValue("check_circle_outline");
      setRefreshTtitle("Enable real time display");
      localStorage.setItem("realTimeDisplay", "false");
      setRealTimeDisplay(false);
    }
    else{
      setSpanValue("check_circle");
      setRefreshTtitle("Disable real time display");
      localStorage.setItem("realTimeDisplay", "true");
      setRealTimeDisplay(true);
    }
  }

  const getData = useCallback(
    async() => {
      try {
        let res = await api.get(BACKEND_URL+'/pointcloud?layer='+layer,{
          headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if(res.data){
            sessionStorage.setItem('layer'+layer, JSON.stringify(res.data.data));
            sessionStorage.setItem('maxVersionLayer'+layer, res.data.maxVersion);
            sessionStorage.setItem('maxSizeLayer'+layer, res.data.maxSize);
            sessionStorage.setItem('maxDateLayer'+layer, res.data.maxDate);
            sessionStorage.setItem('minDateLayer'+layer, res.data.minDate);
            setPointCloudData(Array.isArray(res.data.data) ? res.data.data : []);
            setMaxVersion(res.data.maxVersion);
            setMaxSize(res.data.maxSize);
            setMaxDate(res.data.maxDate);
            setMinDate(res.data.minDate);
        }
      }
      catch(error){
        console.log(error);
      }
    }
  );

  function reorderLayers(){
    const layers = map.current.getStyle().layers;
    if(map.current?.isStyleLoaded()){
      for(const fleet of layers){
        if(fleet.id.startsWith('fleet_')){
          for(const element of pointCloudData){
            if(map.current.getSource('tile_' + element.id.toString())){
              map.current.moveLayer(fleet.id, 'tile_' + element.id.toString());
            }
            if(map.current.getLayer('tile_' + element.id.toString() + '_border')){
              map.current.moveLayer(fleet.id, 'tile_' + element.id.toString() + '_border');
            }
          }
        }
      }
    }

  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if(pointCloudData?.length > 0 && !counter){
      setCounter(true);
      localStorage.setItem('lng', pointCloudData[0].coordinates[0][0]);
      localStorage.setItem('lat', pointCloudData[0].coordinates[0][1]);
      map.current?.flyTo({
        center: [
            pointCloudData[0].coordinates[0][0],
            pointCloudData[0].coordinates[0][1]
        ],
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
    }
  }, [pointCloudData]);

  useEffect(() => {
    if(realTimeDisplay){
      const timer = setInterval(() => {
        const timer2 = setInterval(() => {
          setRefreshingMessage("");
          clearInterval(timer2);
        }, 1000);
        console.log('refresh point cloud...');
        getData();
        reorderLayers();
        setRefreshingMessage("Refreshing...");
      }, REFRESH_DATA_EVERY_X * 1000);
      return () => clearInterval(timer);
    }

  }, [realTimeDisplay, pointCloudData]);

  useEffect(() => {
    map.current?.on('load', function(){
      map.current.on('click', handleClick);
      setTimeout(function(){
        if(Array.isArray(pointCloudData)){
          for(const element of pointCloudData){
            if(!map.current.getSource('tile_' + element.id.toString())){
              map.current.addSource('tile_' + element.id.toString(), {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'geometry': {
                      'type': 'Polygon',
                      'coordinates': [element.coordinates]
                    }
                }
              });
            }
            if(!map.current.getLayer('tile_' + element.id.toString() + '_border')){
              map.current.addLayer({
                'id': 'tile_'+element.id.toString()+'_border',
                'type': 'line',
                'source': 'tile_'+element.id.toString(),
                'layout': {},
                'paint': {
                  'line-color': getTileColor(coloring, element.version, element.size, parseInt(element.timestamp), parseInt(maxVersion), parseInt(maxSize), parseInt(maxDate), parseInt(minDate)),
                  'line-width': 1.5
                }
              });
            }
            if(!map.current.getLayer('tile_' + element.id.toString())){
              map.current.addLayer({
                'id': 'tile_'+element.id.toString(),
                'type': 'fill',
                'source': 'tile_'+element.id.toString(),
                'layout': {},
                'paint': {
                  'fill-color': getTileColor(coloring, element.version, element.size, parseInt(element.timestamp), parseInt(maxVersion), parseInt(maxSize), parseInt(maxDate), parseInt(minDate)),
                  'fill-opacity': (coloring ===0) ? 0 : 0.15
                }
              });
            }
            map.current.on('click', 'tile_'+element.id.toString(), (event) => {
              for(const elem of pointCloudData){
                if(map.current.getPaintProperty('tile_'+elem.id+'_border', 'line-color') === "#0075a9" && elem.id != element.id){
                  map.current.setPaintProperty('tile_'+elem.id+'_border', 'line-color', map.current.getPaintProperty('tile_'+elem.id, 'fill-color'));
                  map.current.setPaintProperty('tile_'+elem.id+'_border', 'line-width', 1.5);
                }
              }
              setDisplayTileOverlay(true);
              dispatch({tileId: element.id, tileVersion: element.version, tileSize: element.size, tileDate: element.date, tileLevel: element.level, tileExtension: element.extension, tileFilePath: element.filePath});
              map.current.setPaintProperty('tile_'+element.id.toString()+'_border', 'line-color', '#0075a9');
              map.current.setPaintProperty('tile_'+element.id.toString()+'_border', 'line-width', 5);
            });
          }
        }
      }, 1000);
      map.current.triggerRepaint();
    });
  },[pointCloudData]);
  useEffect(() => {
    map.current?.triggerRepaint();
    if(map.current?.isStyleLoaded()){
      for(const element of pointCloudData){
        if(map.current.getLayer('tile_'+element.id)){ // layer exists
          map.current.removeLayer('tile_'+element.id);
          map.current.removeLayer('tile_'+element.id+'_border');
          map.current.removeSource('tile_'+element.id);
        }
        map.current.addSource('tile_' + element.id.toString(), {
          'type': 'geojson',
          'data': {
              'type': 'Feature',
              'geometry': {
                'type': 'Polygon',
                'coordinates': [element.coordinates]
              }
          }
        });
        map.current.addLayer({
          'id': 'tile_'+element.id.toString()+'_border',
          'type': 'line',
          'source': 'tile_'+element.id.toString(),
          'layout': {},
          'paint': {
            'line-color': getTileColor(coloring, element.version, element.size, parseInt(element.timestamp), parseInt(maxVersion), parseInt(maxSize), parseInt(maxDate), parseInt(minDate)),
            'line-width': 1.5
          }
        });
        map.current.addLayer({
          'id': 'tile_'+element.id.toString(),
          'type': 'fill',
          'source': 'tile_'+element.id.toString(),
          'layout': {},
          'paint': {
            'fill-color': getTileColor(coloring, element.version, element.size, parseInt(element.timestamp), parseInt(maxVersion), parseInt(maxSize), parseInt(maxDate), parseInt(minDate)),
            'fill-opacity': (coloring === 0) ? 0 : 0.15
          }
        });
        map.current.on('click', 'tile_'+element.id.toString(), (event) => {
          for(const elem of pointCloudData){
            if(map.current.getPaintProperty('tile_'+elem.id+'_border', 'line-color') === "#0075a9" && elem.id != element.id){
              map.current.setPaintProperty('tile_'+elem.id+'_border', 'line-color', map.current.getPaintProperty('tile_'+elem.id, 'fill-color'));
              map.current.setPaintProperty('tile_'+elem.id+'_border', 'line-width', 1.5);
            }
          }
          setDisplayTileOverlay(true);
          dispatch({tileId: element.id, tileVersion: element.version, tileSize: element.size, tileDate: element.date, tileLevel: element.level, tileExtension: element.extension, tileFilePath: element.filePath});
          map.current.setPaintProperty('tile_'+element.id.toString()+'_border', 'line-color', '#0075a9');
          map.current.setPaintProperty('tile_'+element.id.toString()+'_border', 'line-width', 5);
        });
      }
      map.current.triggerRepaint();
    }  
  }, [pointCloudData]);
    return( <div>
              <ToastContainer 
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
              <div className="refreshDiv"><button className="refreshButton" title={refreshTitle} onClick={refreshData}><span className="material-icons">{spanValue}</span></button></div>
              <div className="refreshingMessage">{RefreshingMessage}</div>
              <div>maxVersion: {maxVersion}</div>
              <div>maxSize: {maxSize}</div>
              {displayTileOverlay ? <TileOverlay
                    tileId = {tileMetadata.tileId}
                    tileVersion = {tileMetadata.tileVersion}
                    tileSize = {tileMetadata.tileSize}
                    tileDate = {tileMetadata.tileDate}
                    tileLevel = {tileMetadata.tileLevel}
                    tileExtension = {tileMetadata.tileExtension}
                    tileFilePath = {tileMetadata.tileFilePath}

              />:<div>no data</div>}
            </div>
          )
}
